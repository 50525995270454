import ImageEmpty from '../assets/images/slices/empty1.png'

const Empty = ({ children, title = '没有找到相关的内容 请换一个关键词试试吧～' }) => {
    return ( <div className="empty-view">
        <div className="empty-view-pic">
            <img src={ImageEmpty} alt={title} />
        </div>
        <div className="empty-view-font">
            {title}
        </div>
        {children}
    </div> );
}
 
export default Empty;