import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconRight from '../../assets/images/icons/icon_boult@2x.png'
import PickerCity from '../../commponents/Picker/City';
import { useAxios } from '../../hooks/useAxios';
import { useMessage } from '../../hooks/useMessage';
const AddessPush = () => {

    const [formData, setFormData] = useState({})
    // 表单提交方法
    const [postUrl, setPostUrl] = useState('')
    const [option, setOption] = useState({ method: 'post' })
    const [result, error] = useAxios(postUrl, option)
    const onSubmit = useCallback((event, data) => {
        event.preventDefault()
        setPostUrl('/address/submit')
        setOption(option => Object.assign({}, option, { data }))
    }, [])

    const formAttr = useMemo(() => {
        return [
            {
                key: 'name',
                title: '联系人',
                placeholder: '请输入联系人姓名',
                type: 'Input',
            },
            {
                key: 'mobile',
                title: '手机号码',
                placeholder: '请输入手机号',
                type: 'Input',
            },
            {
                key: 'from',
                title: '地区',
                placeholder: '请选择地区',
                type: 'PickerAddress',
            },
            {
                key: 'detail',
                title: '详细地址',
                placeholder: '请输入详细地址',
                type: 'textarea',
            }
        ]
    }, [])
    const picker = useRef(null)

    // 显示省市区组件
    const onPickAddressOpen = useCallback(() => {
        picker.current.open()
    }, [picker])
    // fromData 更新值方法
    const onChange = useCallback((name, value) => {
        if (value === undefined) return null
        setFormData(data => {
            return Object.assign({}, data, { [name]: value })
        })
    }, [])
    
  
    // 监听每次提交后的状态
    const [message] = useMessage()
    const navTo = useNavigate()
    useEffect(() => {
        if (error) message({title: error})
        if (result) {
            message({title: result?.msg ?? '添加成功'})
            navTo('/address/list')
        }
        //console.log('当前数据', formData)
    }, [result, error, message, navTo])



    return ( <>
        <form className="single-form" onSubmit={event => onSubmit(event, formData) }>
            <div className="single-form-lines">
                { formAttr.map(attr => (<label key={attr.key} className="clearfix border-bottom line-view ">
                    <div className="line-title col_666 fl">
                        {attr.title}
                    </div>

                    {/* 普通文本 */}
                    { attr.type === 'Input' && (<div className="line-val">
                        <input value={formData[attr.key]} placeholder={attr.placeholder} onChange={event => onChange(attr.key, event.target.value)} className="single-form-input" />
                    </div>)}

                    {/* 地区 */}
                    { attr.type === "PickerAddress" && (
                        <>
                            <div className="fr">
                                <div className="right-icon">
                                    <img src={IconRight} alt='' />
                                </div>
                            </div>
                            <div className="line-val">
                                <input placeholder={attr.placeholder} value={formData[attr.key]} onFocus={onPickAddressOpen} className="single-form-input" />
                            </div>
                        </>
                    ) }

                    {/* 文本域 */}
                    {attr.type === 'textarea' && (<div className="line-val">
                        <textarea className="single-form-textarea" placeholder={attr.placeholder} onChange={event => onChange(attr.key, event.target.value)}></textarea>
                    </div>)}
                </label>)) }
                <PickerCity ref={picker} onValueChange={value => onChange('from', value) } />
            </div>
            <div className="single-form-lines">
                <label className="clearfix line-view">
                    <div className="line-title fl">
                        设置默认
                    </div>
                    <div className="line-val">
                        <label className="switch-btn label fr">
                            <input type="checkbox" onChange={event => onChange('is_default', event.target.checked)} />
                            <div className="switch-btn-view"></div>
                        </label>
                    </div>
                </label>
            </div>
            <div className="form-bottom">
                <div className="form-bottom-view page">
                    <button type="submit" className="btn-1em bg_d6b575 form-bottom-btn">确定</button>
                </div>
            </div>		
        </form>
    </> );
}
 
export default AddessPush;