import { useCallback, useEffect, useState } from "react"
import { useMessage } from '../../../hooks/useMessage'
import { useAxios } from "../../../hooks/useAxios"
import { useNavigate } from "react-router-dom"

const SubmitButton = ({goods}) => {
    const [option, setOption] = useState({method: 'POST'})
    const [postUrl, setPostUrl] = useState('')
    const [result, error, isPending] = useAxios(postUrl, option)

    // 数据打包并发送到后台
    const [message] = useMessage()
    // 提交到订单
    const onSumit = useCallback((goods, message) => {
        const list = goods.filter(item => item.checkState)
        if (list.length === 0) message({title: '请先选中购物车中的商品'})
        else {
            const data = {
                goods: list.map(item => {
                    const { id, number, attr } = item
                    return {id, number , attr: attr.title}
                })
            }
            setPostUrl('order/post')
            setOption(target => Object.assign({}, target, { data }))
        }
    }, [])

    // 监听订单的提交状态
    const navTo  = useNavigate()
    useEffect(() => {
        if (error) { message({title: error}) }
        if (result) {
            message({title: result.msg})
            setTimeout(() => {
                navTo('/order/submit/' + result.order_no)
            }, 1500)
        }
    }, [result, error, isPending, message, navTo])

    return ( <div className="fr h5">
    <button
        onClick={() => onSumit(goods, message)}
        type="button"
        className="bg_d6b575 form-bottom-btn"
    >
        结算
    </button>
</div> );
}
 
export default SubmitButton;