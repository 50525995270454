import Icon1 from '../assets/images/icons/footer-icon1.png'
import Icon1On from '../assets/images/icons/footer-icon1-on.png'
import Icon2 from '../assets/images/icons/footer-icon2.png'
import Icon2On from '../assets/images/icons/footer-icon2-on.png'
import Icon3 from '../assets/images/icons/footer-icon3.png'
import Icon3On from '../assets/images/icons/footer-icon3-on.png'
import Icon4 from '../assets/images/icons/footer-icon4.png'
import Icon4On from '../assets/images/icons/footer-icon4-on.png'
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'

/** 底部状态栏  */
const TabBar = () => {

    const barList = useMemo(() => [
        {
            icon: Icon1,
            seleteIcon: Icon1On,
            path: '/iframe/home',
            text: '首页'
        },
        {
            icon: Icon2,
            seleteIcon: Icon2On,
            path: '/iframe/goods/cate',
            text: '分类'
        },
        {
            icon: Icon3,
            seleteIcon: Icon3On,
            path: '/iframe/shopping/card',
            text: '购物车'
        },
        {
            icon: Icon4,
            seleteIcon: Icon4On,
            path: '/iframe/self/index',
            text: '我的'
        }
    ], [])
    const [currentIndex, setCurrentIndex] = useState(-1)

    /** 跳转路由 */
    const navUrl = useNavigate()
    const onSwitch = useCallback((index) => {
        if (index === currentIndex) return null
        const { path } = barList[index]
        navUrl(path) 
    }, [barList, navUrl, currentIndex])

    /** 切换状态栏 */
    const local = useLocation()
    useEffect(() => {
        const { pathname } = local
        const index = barList.findIndex(item => item.path === pathname)
        setCurrentIndex(index)
    }, [local, barList])

    return ( <div className="footer-view">
        <div className="bottom-position">
            <div className="page footer-nav">
                {barList.map((item, index) => (
                    <div onClick={() => onSwitch(index)} className={'footer-link ' + (currentIndex === index ? 'on' : '')} key={index}>
                        <div className="footer-link-icons">
                            <img src={item.icon} alt={item.text} />
                            <img src={item.seleteIcon} alt={'已选中' + item.text} />
                        </div>
                        <div className="footer-link-txt">{item.text}</div>
                    </div>
                ))}
            </div>
        </div>
</div> );
}
 
export default TabBar;