import { useEffect, useRef } from "react";

/**
 * 滚动动画, 默认 300 毫秒
 */
const scrollAnimeTime = 300
const scrollTo = (element, start, end) => {
    return new Promise((resolve) => {
        const go = Date.now()
        const diff = end - start
        const time = Math.min(Math.abs(diff) * 15, scrollAnimeTime)
        const up = () => {
            const now = Date.now()
            let porp = Math.min(1, (now - go) / time)
            if (porp !== 1) {
                element.scrollTop = ~~(diff * porp + start)
                return requestAnimationFrame(up)
            } else {
                element.scrollTop = end
                resolve()
            }
        }
        up()
    })
} 

/**
 * 接受两个节点, 并同步两节点的滚动位置
 */
const joinScroll = (element, target, onIndexChange) => {
    // 预防多次设置
    if (element.joinScroll) return null
    element.joinScroll = true

    // 滚动同步
    element.addEventListener('scroll', function() {
        target.scrollTop = this.scrollTop

        // 滚动停止时, 滚到到最接近中间元素的节点
        clearTimeout(this.scrollEndTimer)
        this.scrollEndTimer =  setTimeout(() => {
            // 获取所有的item
            const items = [...target.firstElementChild.children]
            // 先获取每个item 的高度
            const itemHeight = items[0].offsetHeight
            const offsetTop = items[0].offsetTop
            const scrollTop = this.scrollTop
          
            // 当前的滚动位置 来比每个item 距离顶部的位置
            const positons = items.map(item => Math.abs(scrollTop - (item.offsetTop - offsetTop)))
            const min = Math.min(...positons)
            const index = positons.findIndex(item => item === min)
            scrollTo(target, this.scrollTop, index*itemHeight)
            .then(() => onIndexChange(index))
        }, 150)
    })
}

const PickerCol = ({list, onIndexChange}) => {

    const ctxElement = useRef(null)
    const scorllElement = useRef(null)

    useEffect(() => {
        // 同步高度
        if (ctxElement.current && scorllElement.current) {
            scorllElement.current.firstElementChild.style.height = ctxElement.current.firstElementChild.offsetHeight + 'px'
            joinScroll(scorllElement.current, ctxElement.current, onIndexChange)
        }
    }, [ctxElement, onIndexChange])


    return ( <section className='col-view'>

            {/* 内容层 */}
            <section className="col-ctx" ref={ctxElement}>
                <ul className='col-list'>
                    {list.map(item => (<li key={item.code} className='col-item substr'>
                        <p>{item.name}</p>
                    </li>))}
                </ul>
            </section>

            {/* 看不见的触摸层 */}
            <section className="col-scroll" ref={scorllElement}>
                <div></div>
            </section>
    </section> );
}
 
export default PickerCol;