import { memo, useCallback, useContext, useRef } from "react";
import { GoodsContent } from "./GoodsContent";
import { GoodsContentTitle } from "./GoodsTitlte";
import { GoodsAttr } from "./GoodsAttr";
import { GoodsSwiper } from './GoodsSwiper'
import { GoodsPromote } from "./GoodsPromote";
import { GoodsData } from "./GoodsData";
import { GoodsPopup } from './GoodsPopup'

// 商品详情
export const DetailView = memo(({detail, Ctx}) => {

    const [formData, upFormData] = useContext(Ctx)
    const onPopupUp = useCallback((from) => {
        upFormData(data => {
            return Object.assign({}, data, from)
        })
    }, [upFormData])

    /** 显示属性弹窗 */
    const popupCommponent = useRef(null)
    const onOpenPopup = useCallback(() => {
        popupCommponent.current.open()
    }, [popupCommponent])

    return (<div className="goods-detail">

        <GoodsSwiper ads={detail.ads} />

        <GoodsData detail={detail} formData={formData} />

        <GoodsAttr detail={detail} formData={formData} onShowPopup={onOpenPopup} />

        <GoodsPromote goods={detail.goods} />

        <GoodsContentTitle />

        <GoodsContent content={detail.content} />

        {/* 属性 */}
        <GoodsPopup detail={detail} formData={formData} onUpdate={onPopupUp} ref={popupCommponent} />
    </div>)
})
