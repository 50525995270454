import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios";
import Empty from "../../commponents/Empty2";
import GoodsListTitle from "../../commponents/GoodsListByTitle";

const NewsDetail = () => {

    const [detailUrl, setDetailUrl] = useState('')
    const [detail, error, isPending] = useAxios(detailUrl)
    const { id } = useParams()
    
    useEffect(() => {
        setDetailUrl('news/detail/' + id)
    }, [id])

    return ( <article className="win">

        {isPending && <div className="single-detail"><div className="single-content">正努力加载....</div></div>}
        
        {!isPending && error && <Empty title={error} />}

        {!isPending && detail && (<>
            <div className="single-detail">
                <div className="single-title">
                    {detail.title}
                </div>
                <div className="single-content" dangerouslySetInnerHTML={{__html: detail.content}}></div>
            </div>
            
            {/* 相关推荐 */}
            <GoodsListTitle title={'相关推荐'} goods={detail.goods} />
        </>)}  
        
    </article> );
}
 
export default NewsDetail;