import { memo, useCallback, useEffect, useState } from "react";
import SubmitButton from "./onSumit";
const Footer = memo(({ goods, onUpdate }) => {

    // 计算所选中的所有商品金额
    const [count, setCount] = useState(0)
    const sumGoods = useCallback((goods) => {
        const list = goods.filter(item => item.checkState)
        let n = 0
        list.forEach(element => {
            const { attr, number } = element
            n += attr.price * number
        })
        return n.toFixed(2)
    }, [])
    
    // 计算总价
    useEffect(() => {
        const count = sumGoods(goods)
        setCount(count)
    }, [goods, sumGoods])

    // 监听全选状态
    const [allIn, setAllIn] = useState(false)
 
    // 设置全选
    const onSetAllCheck = useCallback((state) => {
        onUpdate(list => {
            list.forEach(item => { item.checkState = state })
            return [...list]
        })
    }, [onUpdate])

    // 统一计算已选中的商品(全选)
    // 如果用户手动选上了所有, 全选按钮也需要改变
    useEffect(() => {
        const state = goods.filter(item => item.checkState === false)
        setAllIn(state.length === 0)
    }, [goods])
   
    return (
        <div className="cart-bottom">
            <div className="cart-bottom-view page">
                <div className="fl">
                    <label className="label all-choice clearfix">
                        <div className="fl">
                            <input type="checkbox" checked={allIn} onChange={event => onSetAllCheck(event.target.checked)} />
                            <div className="choice-view2"></div>
                        </div>
                        <div className="fl h5 col_666">全选</div>
                    </label>
                </div>
                <div className="overhide cart-count">
                    <SubmitButton goods={goods} />
                    <div className="col_b01f29 h5">
                        <strong>
                            合计: ¥ <span>{count}</span>
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Footer