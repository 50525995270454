import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const GoodsList = ({goods}) => {
	
	const navUrl = useNavigate()
	const onSwitch = useCallback((goodID) => {
		navUrl('/goods/detail/' + goodID)
	}, [navUrl])

    return ( <div className="goods-list">
        {goods.map(item => (
			<div onClick={() => onSwitch(item.id)} className="goods-itme border-bottom clearfix" key={item.id}>
				<div className="goods-item-pic fl bg_f7f7f7">
					<img src={item.pic} alt={item.title} />
				</div>
				<div className="goods-item-data">
					<div className="goods-item-title h5">
						{item.title}
					</div>
					<div className="goods-item-val middl-text">
						<b className="col_b01f29 shopping-val">
							<span>¥</span>{item.price}
						</b>
						<img src={item.icon} alt={item.sub_title} />
					</div>
					<div className="goods-item-txt col_999">{item.sub_title}</div>
				</div>
			</div>
		))}
    </div> );
}
 
export default GoodsList;