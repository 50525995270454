import { Link, useParams } from 'react-router-dom';
import IconSuccess from '../../assets/images/icons/icon_chenggong@2x.png'
import IconError from '../../assets/images/icons/icon_shibai@2x.png'

const OrderResult = () => {

    const { order_no, state } = useParams()
    const msg = state === '0' ? '支付失败' : '支付成功'
    const icon = state === '0' ? IconError : IconSuccess

    return ( <div className="order-statu">
    <div className="order-statu-icon">
        <div className="order-statu-iconi">
            <img src={icon} alt={msg} />
        </div>
        <p className="h2 col_666">{msg}</p>
    </div>
    {
        state === '1' && (<>
        <div className="order-statu-btns">
            <Link to={'/order/detail/' + order_no} className="order-statu-btn bg_d6b575">查看订单</Link>
            <Link to={'/'} className="order-statu-btn border">完成</Link>
        </div>
        </>)
    }

    {
        state === '0' && (<>
        <div className="order-statu-btns">
            <Link to={'/order/submit/' + order_no} className="order-statu-btn bg_d6b575">重新支付</Link>
            <Link to={'/'} className="order-statu-btn border">取消支付</Link>
        </div>
        </>)
    }
</div> );
}
 
export default OrderResult;