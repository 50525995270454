import { memo, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../../hooks/useAxios";
import { useMessage } from "../../../hooks/useMessage";
const GoodsPayButton = memo(({Ctx}) => {

    const [option, setOption] = useState({method: 'POST'})
    const [postUrl, setPostUrl] = useState('')
    const [result, error, isPending] = useAxios(postUrl, option)
    const [message] = useMessage()
    const [formData] = useContext(Ctx)

    /**
     * 数据提交格式
{
    "goods": [
        {
            "attr": "福禄双喜礼盒",
            "number": 1,
            "id": 10
        }
    ]
}
     */

    const navTo  = useNavigate()
    useEffect(() => {
        if (error) { message({title: error}) }
        if (result) {
            message({title: result.msg})
            setTimeout(() => {
                navTo('/order/submit/' + result.order_no)
            }, 1500)
        }
    }, [result, error, isPending, message, navTo])

    const onPay = useCallback(() => {
        if (formData.attr === null) { message({title: '请先选择商品规则~'}) }
        else { // 处理 option 数据
            const { id, number, attr } = formData
            const data = {
                goods: [ { attr: attr.title, id, number, } ]
            }
            setPostUrl('order/post')
            setOption(target => Object.assign({}, target, { data }))
        }
    }, [message, formData])

    return ( <button onClick={onPay} type="button" className="btn-1em bg_d6b575">
        立即购买
    </button> );
})
 
export default GoodsPayButton;