import React from 'react';
import ReactDOM from 'react-dom/client';
import 'swiper/css'
import 'swiper/css/pagination'
import './assets/index.css';
import App from './App';


/** 初始化手机稿 */
;(function () {
  const reset = () => {
    const html = document.querySelector('html')
    const {width: deviceWidth} = html.getBoundingClientRect()
    const tagretWidth = 750
    const fontSize = Math.min(100, deviceWidth / tagretWidth * 100)
    html.style.fontSize = fontSize + 'px'
  }
  reset()
  window.onresize = reset
} ())

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
      <App />
    // </React.StrictMode>
);
