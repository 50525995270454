import { memo, useCallback, useState, useMemo, useEffect } from "react";
import Empty from "../../commponents/Empty";
import { useAxios } from "../../hooks/useAxios";
import { useMessage } from '../../hooks/useMessage'
import IconDel from '../../assets/images/icons/icon_delete@2x.png'
import IconSuccess from '../../assets/images/icons/complete.png'

const GoodItem = memo(({ good }) => {

    const [message] = useMessage()

    const [state, setState] = useState(true)
    const [followUrl, setFollowUrl] = useState('')
    const opstion = useMemo(() => ( { method: 'POST' } ), [])
    const [result, error, isPending] = useAxios(followUrl, opstion)
    const onSwitch = useCallback(() => {
        if (isPending === true && followUrl !== '') { message({title: '网络正在处理中...'}) }
        else {
            const url = `goods/follow/${good.id}?_=` + Date.now()
            setFollowUrl(url)
        }
    }, [setFollowUrl, message, isPending, followUrl, good])

    // 监听请求返回 
    useEffect(() => {
        if (error) { message({title: error}) }
        if (result) {
            const icon = <img src={IconSuccess} alt="success" />
            message({title: result.msg, icon})
            setState(result.follow)
        }
    }, [result, error, message])

    return (<div className={'goods-itme border-bottom clearfix ' + (state ? '' : 'disabled')}>
        <div className="goods-item-pic fl">
            <img src={good.pic} alt={good.title} />
        </div>
        <div className="goods-item-data">
            <div className="goods-item-title h5">
                {good.title}
            </div>
            <div className="goods-item-val middl-text">
                <b className="col_b01f29 shopping-val">
                    <span>¥</span>{good.price}
                </b>
                <img src={good.icon} alt='' />
            </div>
            <div className="goods-item-txt col_999">
                {good.sub_title}
                <span className="fr goods-delink" onClick={onSwitch}>
                    <img src={IconDel} alt='切换收藏状态' />
                </span>
            </div>
        </div>
    </div>)
})

const SelfFollow = () => {

    const [result, error, isPending] = useAxios('self/follow')

    return ( <>

        {isPending && <div className="h6">正在加载...</div>}
        
        {(error || result?.length === 0) && <article className="win"><Empty title={error ?? '暂无收藏'} /></article>}
        
        {result && result.length > 0 && ( <article className="win">
            <div className="goods-list">
                {result.map(item => <GoodItem key={item.id} good={item} />)}
            </div>
        </article>)}
    </> );
}
 
export default SelfFollow;