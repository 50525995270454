import axios from "axios";
import { useCallback, useEffect, useState } from "react";

const instance = axios.create({
    baseURL: '/v1/',
    timeout: 0
})

export const useAxios = (url, option) => {
    const [result, setResult] = useState(null)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(true)
    const reLoading = useCallback(async () => {
        setResult(null)
        setError(null)
        setIsPending(true)
        try {
            const result = await instance({
                ...(option ?? {}), url
            })
            if (result?.data) setResult(result.data?.data ?? result.data)
            else setError('空空如也~')
        } catch (err) {
            const errMsg = err?.response?.data?.msg ?? err?.message ?? '网络异常~'
            setError(errMsg)
        }
        setIsPending(false)
    }, [url, option])

    useEffect(() => {
        if (url) reLoading()
    }, [reLoading, url])

    return [result, error, isPending, reLoading]
}
