import IconSearch from '../../assets/images/icons/search2.png'
import IconClose from '../../assets/images/icons/close.png'
import { memo, useCallback, useEffect, useRef, useState, forwardRef } from 'react'
import { useAxios } from '../../hooks/useAxios'
import Empty from '../../commponents/Empty'
import GoodsList from '../../commponents/GoodsList'
import { useSearch } from '../../store/searchHistory'

const FormView = memo(({ onSearch, inputValue }) => {

    const [value, setValue] = useState('')
    /** 触发搜索 */
    const onSubmit = useCallback((value) => {
        if (value === '') alert('请输入搜索关键字')
        onSearch(value)
    }, [onSearch])

    /** 清空输入框 */
    const onClear = useCallback(() => {
        setValue('')
        onSearch('')
    }, [onSearch])

    useEffect(() => {
        setValue(inputValue)
    }, [inputValue])

    return (<>
        <form className="line-from clearfix" onSubmit={event => {
            event.preventDefault()
            onSubmit(value)
        }}>
            <button type="submit" className="fr line-from-btn">
                搜索
            </button>
            <div className="overhide search-input">
                <div className="fl icon">
                    <img src={IconSearch} alt="图标" />
                </div>
                {
                    value.length !== 0 && (
                    <div className="fr icon" onClick={onClear}>
                        <img src={IconClose} alt="清空输入" />
                    </div>
                    )
                }
                <div className="overhide">
                    <input required value={value} onChange={event => setValue(event.target.value)} placeholder="请输入关键词" className="img-full" />
                </div>

            </div>
        </form>
    </>)
})

const FromHistory = forwardRef(({ onSearch }, ref) => {

    const [history, pushHistory, clearHistory] = useSearch()
   
    useEffect(() => {
        ref.current = { onPushHistory: pushHistory }
    }, [ref, pushHistory])

    return (<>
        <div className="search-content">
            <div className="search-history">
                <div className="clearfix search-history-head">
                    <strong>搜索历史</strong>
                    { history && history.length !== 0 && <span onClick={clearHistory} className="fr col_999" >清除记录</span>}
                </div>
                <div className="clearfix search-history-list">
                    {history && history.map(item => (
                        <div onClick={() => onSearch(item.text)} className='search-history-item' key={item.time}>{item.text}</div>
                    ))}
                </div>
            </div>
        </div>
    </>)
})

const SearchIndex = () => {

    const historyFC = useRef(null)
    const [searchValue, setSearchValue] = useState('')
    const [searchUrl, setSearchUrl] = useState('')
    const [result, error, isPending] = useAxios(searchUrl)

    /** 触发搜索 */
    useEffect(() => {
        if (searchValue !== '') {
            const url = 'home/search/' + searchValue
            historyFC.current.onPushHistory(searchValue)
            setSearchUrl(url)
        } else {
            setSearchUrl('')
        }
    }, [searchValue])


    return ( <article className='win'>
        {/* 搜索框 */}
        <FormView onSearch={setSearchValue} inputValue={searchValue} />

        {/* 搜索历史 */}
        { (searchUrl.length === 0) && <FromHistory ref={historyFC} onSearch={setSearchValue} /> }

        {/** 搜索结果 */}
        <div className='search-content'>
            {(searchValue.length !== 0 && isPending) && <div className='h6'>加载中....</div>}

            { ( (!isPending && error) || (result?.list?.length === 0)) && <Empty />}
            
        </div>
        { !isPending && searchUrl.length !== 0 && result?.list?.length !== 0 && <GoodsList goods={result.list} /> }
    </article> );
}
 
export default SearchIndex;