import { memo, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAxios } from '../../hooks/useAxios'
import Empty from "../../commponents/Empty2";

/** 顶部状态栏 */
const TopView = memo(({onSwitch}) => {

    const [bars] = useAxios('news/cate')
    const [index, setIndex] = useState(-1)

    useEffect(() => {
        if (index !== -1) onSwitch && bars && onSwitch(bars[index].id)
    }, [index, onSwitch, bars])

    // 默认选中第一个
    useEffect(() => {
        if (bars && bars.length >= 1) {
            setIndex(0)
        }
    }, [bars])

    return (
        <div className="tab-head flex-line container border-bottom">
            {bars === null && <div className="tab-nav h5 on">努力加载中....</div>}
            {bars !== null && bars.map((item, i) => (
                <div onClick={() => (index !== i && setIndex(i)) }  key={item.id} className={'tab-nav h5 ' + (index === i ? 'on' : '') }>{item.title}</div>
            ))}
        </div>
    )
})

const NewsIndex = () => {

    const [listUrl, setListUrl] = useState('')
    const [result, error, isPending] = useAxios(listUrl)
    const onSwitch = useCallback((id) => {
        setListUrl('news/list/' + id)
    }, [])

    return ( <>
        <div className="tab-view">
            <TopView onSwitch={onSwitch} />
            <div className="container">

                {!isPending && error && <Empty title={error} />}

                <div className="news-list">
                    {!isPending && result?.list?.length !== 0 && result.list.map(item => (
                        <Link to={'./detail/' + item.id} className="news-item border-bottom clearfix" key={item.id}>
                            <div className="fr news-pic">
                                <img src={item.pic} alt={item.title} className="img-full"/>
                            </div>
                            <div className="news-font">
                                <div className="h4">{item.title}</div>
                                <p className="news-txt col_999">{item.views}浏览 {item.char}评论</p>
                            </div>
                        </Link>
                    ))}
                    
                </div>
            </div>
        </div>
    </> );
}
 
export default NewsIndex;