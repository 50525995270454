import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Autoplay } from "swiper"
import { useAxios } from '../../hooks/useAxios'
import IconSearch from '../../assets/images/icons/search.png'
import IconPinzhi from '../../assets/images/icons/icon_pinzhi@2x.png'
import IconDiQiu from '../../assets/images/icons/icon_diqiu@2x.png'
import IconYezi from '../../assets/images/icons/icon_yezi@2x.png'
import IconMore from '../../assets/images/icons/icon_more@2x.png'
import LogoTxt from '../../assets/images/slices/footer-txt.png'
import { memo, useCallback } from "react"
import Empty from '../../commponents/Empty'
import { useNavigate } from "react-router-dom"

/** 顶部广告 */
const TopSwipte = memo(({ top }) => {
    return (<>
        <div className="home-ad">
            <Swiper
                spaceBetween={30}
                pagination={true}
                modules={[Pagination, Autoplay]}
                autoplay={true}
            >
                {top.map((item, index) => (<SwiperSlide key={index}>
                    <div className="home-ad-link">
                        <img src={item.img} alt={item.title} className="img-full"/>
                    </div>
                </SwiperSlide>))}
            </Swiper>
        </div>
    </>)
})

/** 文档碎片 */
const PageIframe1 = memo(() => {
    return (<>
        <div className="container flex-line home-promise">
			<div className="middl-text col_d6b575 promise-item">
				<img src={IconPinzhi} alt="品质" />
				<span>品质优选享安心</span>
			</div>
			<div className="middl-text col_d6b575 promise-item">
				<img src={IconDiQiu} alt="全国包邮" />
				<span>全国包邮</span>
			</div>
			<div className="middl-text col_d6b575 promise-item">
				<img src={IconYezi} alt='福建原产地直采' />
				<span>福建原产地直采</span>
			</div>
		</div>
    </>)
})

/** 茶友推荐 */
const TasteView = memo(({taste, onTapGoods}) => {
    return (<>
        <div className="container home-top-view">
			<h2 className="h2">茶友品鉴</h2>
            <div className="home-top-list">
            <Swiper
                slidesPerView={"auto"}
            >
                {taste.map(item => (
                    <SwiperSlide key={item.id} className="home-top-item">
                        <div onClick={() => onTapGoods(item.id)}>
                            <div className="home-top-pic bg_f7f7f7">
                                <img src={item.img} alt={item.title} className="img-full" />
                            </div>
                            <div className="home-top-title">
                                <h4 className="h4">{item.title}</h4>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            </div>
		</div>
    </>)
})

/** 爱心珍藏 */
const LikeView = memo(({like, onTapGoods}) => {
    return (<>
        <div className="container home-shopping">
			<h2 className="h2">爱心珍藏</h2>
			<div className="home-shopping-list">
                { like.map(item => (
                    <div onClick={() => onTapGoods(item.id)} className="border shopping-item" key={item.id}>
					    <div className="shopping-pic bg_f7f7f7">
                            <img src={item.pic} alt={item.title} className="img-full" />
                        </div>
                        <div className="shopping-font">
                            <div className="clearfix">
                                <b className="fr col_b01f29 shopping-val">
                                    <span>¥</span>{item.price}
                                </b>
                                <div className="shopping-title">
                                    <img src={item.icon} alt="" className="fl" />
                                    <h4 className="h4 substr">{item.title}</h4>
                                </div>
                            </div>
                            <div className="shopping-txt col_999">{item.sub_title}</div>
                        </div>
                    </div>
                )) }
			</div>
		</div>
    </>)
})

/** 美好生活 */
const NewsView = memo(({news, onTapNews, onTapNewsMore}) => {
    return (<>
        <div className="home-news">
			<h2 className="h2">
				美好生活
				<div onClick={onTapNewsMore} className="fr more-icon">
					<img src={IconMore} alt='查看更多' />
				</div>
			</h2>
			<div className="news-list">
				{news.map(item => (
                    <div onClick={() => onTapNews(item.id)} key={item.id} className="news-item border-bottom clearfix">
                        <div className="fr news-pic bg_f7f7f7">
                            <img src={item.pic} alt={item.title} className="img-full"/>
                        </div>
                        <div className="news-font">
                            <h4 className="h4">{item.title} </h4>
                            <p className="news-txt col_999">{item.views}浏览</p>
                        </div>
                    </div>
                ))}
			</div>
		</div>
    </>)
})

const HomeIndexStatic = memo(({detail}) => {

    const navigate = useNavigate()

    // 商品详情
    const onTapGoods = useCallback((id) => {
        navigate('/goods/detail/'+id)
    }, [navigate])

    /** 新闻详情 */
    const onTapNews = useCallback((id) => {
        navigate('/news/detail/'+id)
    }, [navigate])

    /** 更多新闻 */
    const onTapNewsMore = useCallback(() => {
        navigate('/news')
    }, [navigate])

    /** 跳转搜索详情页 */
    const onTapSearch = useCallback(() => {
        navigate('/search')
    }, [navigate])

    return ( <>
        <div className="bg-full home-head">
            <div onClick={onTapSearch} className="middl-text radius search-view">
                <img src={IconSearch} className="search-icon" alt='' />
                <span className="col_999">{detail.word}</span>
            </div>
            <TopSwipte top={detail.top} />
            <PageIframe1 />
        </div>

        <div className="home-body">
            {/* 茶友品鉴 */}
            <TasteView taste={detail.taste} onTapGoods={onTapGoods} />

            {/* 爱心珍藏 */}
            <LikeView like={detail.like} onTapGoods={onTapGoods} />

            {/*  美好生活 */}
            <NewsView news={detail.news} onTapNews={onTapNews} onTapNewsMore={onTapNewsMore} />
        </div>

        <div className="footer-txt">
            <img src={LogoTxt} alt="本源甘露" />
        </div>
    </> );
})
 
const HomeIndex = () => {
    const [result, error, isPending] = useAxios('home/index')
    return (<>
        {isPending && <div className="h6">正在努力加载...</div>}
        {error && <Empty title={error} />}
        {result !== null && <HomeIndexStatic detail={result} />}
    </>)
}

export default HomeIndex;