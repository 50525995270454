import { memo, useCallback } from "react";
import GooodsNumber from "./GoodsNumber";


const Body = memo(({ goods, onUpdate }) => {

    // 选中状态改变事件
    const onCheckChange = useCallback((good, state) => {
        good.checkState = state
        onUpdate(goods => [...goods])
    }, [onUpdate])

    return (<>
        <div className="cart-list">
            {goods.map((item, index) => (<div key={item.id + index} className="single-form-item clearfix">
				<div className="fl">
					<label className="label alone-choice clearfix">
						<div className="fl">
							<input type="checkbox" checked={item.checkState} onChange={event => onCheckChange(item, event.target.checked)} />
							<div className="choice-view2"></div>
						</div>
					</label>
				</div>
				<div className="goods-itme overhide">
					<div className="goods-item-pic fl">
						<img src={item.pic} alt={item.title} />
					</div>
					<div className="goods-item-data">
						<div className="clearfix h5">
							<div className="substr">
								{item.title}
							</div>
						</div>
						<div className="goods-item-txt col_999">{item.attr.title}</div>
						<div className="clearfix">
							<GooodsNumber good={item} onUpdate={onUpdate} />
							<div className="col_b01f29 h6 overhide">
								等级价 ¥{item.attr.price}
							</div>
						</div>
					</div>
				</div>
			</div>))}
        </div>
    </>)
})

export default Body