import { memo } from "react";
import IconShoppingCart from '../../../assets/images/icons/icon_goods@2x.png'
import { Link } from "react-router-dom"
import GoodsFollow from "./GoodsFollow";
import GoodsPayButton from "./GoodsPayButton";
import ShoppingCartButton from "./ShoppingCartButton";

// 商品底部状态栏
export const BottomBar = memo(({detail, Ctx}) => {

    return (
        <div className="footer-view">
            <div className="bottom-position">
                <form className="page goods-pay-bottom clearfix">
                    <div className="fl goods-btns">
                        <GoodsFollow follow={detail.follow} id={detail.id} />
                        <Link to='/iframe/shopping/card' className="fl goods-btn">
                            <div className="goods-btn-icon">
                                <img src={IconShoppingCart} alt="查看购物车" />
                            </div>
                            <div className="goods-btn-txt">购物车</div>
                        </Link>
                    </div>
                    <div className="goods-pay-btns flex-line">
                        <ShoppingCartButton Ctx={Ctx} />
                        <GoodsPayButton Ctx={Ctx} />
                    </div>
                </form>
            </div>
        </div>
    )
})