import { Link } from "react-router-dom";
import IconMore from '../assets/images/icons/moren.png'
import IconRight from '../assets/images/icons/icon_boult@2x.png'
import { memo, useEffect } from "react";
import { useAddress } from "../store/getAddress";
const AddressDefault = memo(({ onAddressIDChange}) => {
    
    const [address, error, isPending] = useAddress()
    useEffect(() => {
        if (address?.id && onAddressIDChange) onAddressIDChange(address?.id)
    }, [address, onAddressIDChange])

    return ( <Link to='/address/list' className="address-data neon-bottom">
        {isPending && <div className="h6">正在获取地址...</div>}
        { address && (<div>
            <div className="address-region middl-text">
                {address.is_default && <img src={IconMore} alt='默认' />}
                <span className="h6">{address.from}</span>
            </div>
            <div className="address-house">
                <div className="right-icon fr">
                    <img src={IconRight} alt='更多' />
                </div>
                <strong>{address.detail}</strong>
            </div>
            <div className="h6">
                {address.name} {address.mobile.substring(0, 3) + '****' + address.mobile.substring(7, 11)}
            </div>
        </div>) }

        { error && (<div>
            <div className="address-house">
                <div className="right-icon fr">
                    <img src={IconRight} alt='更多' />
                </div>
                <strong>{error}</strong>
            </div>
        </div>)}
    </Link> );
})
 
export default AddressDefault;