import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import IconLikeEmpty from '../../../assets/images/icons/like-empty.png'
import IconLike from '../../../assets/images/icons/like.png'
import IconSuccess from '../../../assets/images/icons/complete.png'
import { useAxios } from '../../../hooks/useAxios'
import { useMessage } from '../../../hooks/useMessage'

const GoodsFollow = memo(({follow, id, children}) => {
    const opstion = useMemo(() => ( { method: 'POST' } ), [])
  
    const [followUrl, setFollowUrl] = useState('')
    const [result, error, isPending] = useAxios(followUrl, opstion)
    const [state, setState] = useState(follow)
    const [message] = useMessage()

    // 切换收藏状态
    const switchFollow = useCallback(() => {
        if (isPending === true && followUrl !== '') { message({title: '网络正在处理中...'}) }
        else {
            const url = `goods/follow/${id}?_=` + Date.now()
            setFollowUrl(url)
        }
    }, [id, isPending, message, followUrl])

    // 监听请求返回
    useEffect(() => {
        if (error) { message({title: error}) }
        if (result) {
            const icon = <img src={IconSuccess} alt="success" />
            message({title: result.msg, icon})
            setState(result.follow)
        }
    }, [result, error, message])

    return (
        <div onClick={switchFollow} className="fl goods-btn" id="like-btn">
            <div className="goods-btn-icon">
                <img src={state ? IconLike : IconLikeEmpty} alt="收藏" />
                </div>
            <div className="goods-btn-txt">收藏</div>
        </div>
    );
})
 
export default GoodsFollow;