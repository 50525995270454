import { memo, useCallback, useContext, useEffect, useState } from "react";
import { useAxios } from "../../../hooks/useAxios";
import { useMessage } from "../../../hooks/useMessage";
const ShoppingCartButton = memo(({Ctx}) => {

    const [option, setOption] = useState({method: 'POST'})
    const [postUrl, setPostUrl] = useState('')
    const [result, error] = useAxios(postUrl, option)
    const [message] = useMessage()
    const [formData] = useContext(Ctx)

    useEffect(() => {
        if (error) { message({title: error}) }
        if (result) { message({title: result.msg}) }
    }, [error, result, message])

    const onPutCart = useCallback(() => {
        if (formData.attr === null) { message({title: '请先选择商品规则~'}) }
        else { // 处理 option 数据
            const { id, number, attr } = formData
            const data = { number, attr: attr.title }
            setPostUrl('goods/shopping/cart/' + id)
            setOption(target => Object.assign({}, target, { data }))
        }
    }, [message, formData])

    return (  <button onClick={onPutCart} type="button" className="btn-1em bg_b01f29">
        加入购物车
    </button> );
})
 
export default ShoppingCartButton;