import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import { Provider } from './hooks/useMessage'

const App = () => {
  return (
    <main className='h6'>
      <div className="page">
        <Provider>
          <RouterProvider router={router} />
        </Provider>
      </div>
    </main>
  );
}

export default App;
