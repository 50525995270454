import { createContext, memo, useCallback, useContext, useEffect, useRef, useState } from "react";
const Context = createContext()

/**=============== 信息弹窗 ===============*/
const Messagae = memo(({data}) => {
    const el = useRef()
    const { setMessages } = useContext(Context)
    const remove = useCallback(() => {
        setMessages(list => {
            // 删除在内存中的提示信息
            return list.filter(item => {
                // 如果有遗漏的弹窗信息, 需要过滤
                const now = Date.now()
                if (now >= (item.now + item.delay)) return false
                return item.uuid !== data.uuid
            })
        })
    }, [data, setMessages])

    useEffect(() => {
        const { current } = el
       
        // 可能会有问题, 需要在下一帧显示
        setTimeout(() => {
            if (current.classList.contains('show') === false) {
                current.classList.add('show')
            }
        }, 100)

        // 监听动画过渡结束事件
        current.addEventListener('transitionend', function (event) {
            const { propertyName } = event
            const inOut = this.classList.contains('out')
            // 如果不存在 out 类名, 证明进入
            if (propertyName === 'opacity' && !inOut) {
                clearTimeout(this.timerRemove)
                this.timerRemove = setTimeout(() => {
                    this.classList.add('out')
                }, data.delay)
            }
            
            if (propertyName === 'transform' && inOut && this.parentElement) {
                this.parentElement.removeChild(this)
                // 当节点删除时, 还要清空上下文中的数据
                remove()
            }
        })
    }, [el, data, remove])

    return (<div className="msg-view" ref={el}>
        { data.icon && (<div className="msg-icon">{data.icon}</div>) }
        <p>{data.title}</p>
    </div>)
})
const MessagaeContainer = memo(() => {
    const { messages } = useContext(Context)
    return (<aside className="msg-container">
        {messages.filter(item => {
            const now = Date.now()
            if (now >= (item.now + item.delay)) return false
            return true
        }).map(item => (<div key={item.uuid}>
            <Messagae data={item} />
        </div>))}
    </aside>)
})
/**=============== 信息弹窗 ===============*/

/**=============== 确认信息弹窗 ===============*/
const Confirm = memo(({data}) => {

    const { setConfirm } = useContext(Context)
    const onConfirm = useCallback((confirm, data) => {
        if (confirm === true) data.success && data.success()
        if (confirm === false) data.fail && data.fail()
        setConfirm(list => {
            return list.filter(item => item.uuid !== data.uuid)
        })
    }, [setConfirm])

    return (<>
        <div className="popup-view-child page">
            <div className="confirm-view">
                <div className="confirm-text">
                    {data.content}
                </div>
                <div className="confirm-btns">
                    {data.hideCancelBtn !== true && <div className="confirm-btn" onClick={() => onConfirm(false, data)}>{data.cancelText ?? '关闭'}</div>}
                    <div className="confirm-btn" onClick={() => onConfirm(true, data)} style={{color: data.confirmColor ?? '#D6B575'}}>{data.confirmText ?? '确认'}</div>
                </div>
            </div>
        </div>
    </>)
})
const ConfirmContainer = memo(() => {
    const { confirm } = useContext(Context)
    return (<aside className="confirm-container">
        {confirm.map(item =>  <Confirm data={item} key={item.uuid} />)}
    </aside>)
})
/**=============== 确认信息弹窗 ===============*/

let uuid = 0
export const Provider = memo(({children}) => {

    const [messages, setMessages] = useState([])
    const [confirm, setConfirm] = useState([
        /*{
            uuid: uuid++,
            content: '是否执行当前操作',
            cancelText: '取消',
            confirm: '确认',
            hideCancelBtn: true,
            success() {
                console.log('确认')
            },
            fail() {
                console.log('取消')
            }
        }*/
    ])

    return (<Context.Provider value={ {messages, setMessages, confirm, setConfirm} }>
        <MessagaeContainer />
        <ConfirmContainer />
        {children}
    </Context.Provider>)
})

export const useMessage = () => {
    const { setMessages } = useContext(Context)
    const showMessage = useCallback(({title, icon, delay = 1500}) => {
        setMessages(list => {
            uuid++ // 生成唯一的uuid
            const now = Date.now()
            return [{title, icon, delay, uuid, now}, ...list]
        })
    }, [ setMessages ])

    return [ showMessage ]
}

export const useConfirm = () => {
    const { setConfirm } = useContext(Context)
    const showConfirm = useCallback((op = {}) => {
        setConfirm(list => {
            uuid++ // 生成唯一的uuid
            return [{uuid, ...op}, ...list]
        })
    }, [ setConfirm ])

    return [ showConfirm ]
}
