import { memo } from "react";
import { useSelf } from "../../../store/getSelf";
const PrintSimple = memo(({val = 0}) => {
    const [selfData] = useSelf()
    
    return (<div className="single-form-item">
    <div className="line-views">
        <div className="line-view clearfix">
            <div className="line-title fl">
                商品金额
            </div>
            <div className="line-val fr">
                ¥{(val / selfData?.discount ?? 1).toFixed(2)}
            </div>
        </div>
        
        <div className="line-view clearfix">
            <div className="line-title fl">
                运费
            </div>
            <div className="line-val col_b01f29 fr">
                + ¥0 
            </div>
        </div>
        <div className="line-view clearfix">
            <div className="line-title fl">
                爱心优惠 <span className="col_999"> 好茶友 全场{selfData?.discount * 10}折</span>
            </div>
            <div className="line-val col_b01f29 fr">
                - {(val / selfData?.discount - val).toFixed(2)}
            </div>
        </div>
        <div className="order-detail-val">
            实付款 <b className="fr col_b01f29">¥{val}</b>
        </div>
        {/* <div className="line-view clearfix">
            <div className="line-title fl">
                实付金额
            </div>
            <div className="line-val fr">
                ¥{val}
            </div>
        </div> */}
    </div>
</div>)
})

export default PrintSimple