import { atom, useAtom } from "jotai"
import { useEffect, useState } from "react"
import { useAxios } from "../hooks/useAxios"

const store = atom(null)

export const useSelf = () => {
    
    const [saveStore, setStore] = useAtom(store)
    const [getUserDataUrl, setUserDataUrl] = useState('')
    const [result, error] = useAxios(getUserDataUrl)

    useEffect(() => {
        if (saveStore === null) setUserDataUrl('self/get')
    }, [saveStore])

    useEffect(() => {
        if (result !== null) setStore(result)
    }, [result, setStore])

    return [saveStore, error]
}