import { memo, useCallback, useRef } from "react";
import Model from '../../commponents/Model'
import { useMessage } from '../../hooks/useMessage'
import { useSelf } from '../../store/getSelf'
import ShareImg from '../../assets/images/slices/share.png'
import IconOrder1 from '../../assets/images/icons/order1.png'
import IconOrder2 from '../../assets/images/icons/order2.png'
import IconOrder3 from '../../assets/images/icons/order3.png'
import IconOrder4 from '../../assets/images/icons/order4.png'

import IconCenter1 from '../../assets/images/icons/center1.png'
import IconCenter2 from '../../assets/images/icons/center2.png'
import IconCenter3 from '../../assets/images/icons/center3.png'
import IconCenter4 from '../../assets/images/icons/center4.png'
import IconCenter5 from '../../assets/images/icons/center5.png'
import IconCenter6 from '../../assets/images/icons/center6.png'
import IconHot from '../../assets/images/slices/hot.png'
import { Link, useNavigate } from "react-router-dom";
import Empty from "../../commponents/Empty";

const UHeader = memo(() => {

    const [useData] = useSelf()

    return (<div className="udetail-head" style={{height: '3.2rem'}}>
        {useData && (<>
        <div className="user-lihe clearfix">
            <div className="fl user-cover">
                <img src={useData.cover} alt={useData.name} className="img-full"/>
            </div>
            <div className="overhide user-data">
                <div className="user-name middl-text">
                    <b>{useData.name}</b>
                    <img src={useData.level} alt='等级' />
                </div>
                <div className="user-mobile">{useData.moblie?.substr(0, 3)  + '****' + useData.moblie?.substr(7, 11) }</div>
            </div>
        </div>
        <div className="udetail-case flex-line">
            <div className="udetail-case-item">
                <b>{useData.store}</b>
                <p>账户余额(元)</p>
            </div>
            <div className="udetail-case-item">
                <b>{useData.discount * 10}折</b>
                <p>爱心优惠</p>
            </div>
            <div className="udetail-case-item">
                <b>{useData.children_count}</b>
                <p>我的成果(人)</p>
            </div>
        </div>
        </>)}
    </div>)
})

const OrderRoot = memo(() => {

    const navTo = useNavigate()
    const onClick = useCallback((state) => {
        let url = '/order/list/' + state
        navTo(url)
    }, [navTo])


    return (<div className="center-item">
    <div className="center-nav order-nav clearfix">
        <div className="center-nav-item fl" onClick={() => onClick(0)}>
            <div className="center-nav-icons">
                <img src={IconOrder1} alt='待付款' className="img-full" />
            </div>
            <h6 className="center-nav-title">待付款</h6>
        </div>
        <div className="center-nav-item fl" onClick={() => onClick(1)}>
            <div className="center-nav-icons">
            <img src={IconOrder2} alt='待确认' className="img-full" />
            </div>
            <h6 className="center-nav-title">待确认</h6>
        </div>
        <div className="center-nav-item fl"  onClick={() => onClick(2)}>
            <div className="center-nav-icons">
            <img src={IconOrder3} alt='已完成' className="img-full" />
            </div>
            <h6 className="center-nav-title">已完成</h6>
        </div>
        <div className="center-nav-item fl" onClick={() => onClick(-1)}>
            <div className="center-nav-icons">
                <img src={IconOrder4} alt='全部订单' className="img-full" />
            </div>
            <h6 className="center-nav-title">全部订单</h6>
        </div>
    </div>
</div>)
})

const Console = memo(() => {

    const shareModel = useRef(null)
    const onShare = useCallback(() => {
        shareModel.current.open()
    }, [])

    const [message] = useMessage()

    return (<>
        <div className="center-item">
            <h3 className="h3 center-title">我的服务</h3>
            <div className="center-nav center-nav-multi clearfix">
                <div className="center-nav-item fl" onClick={() => message({title: '系统升级中...'})}>
                    <div className="center-nav-icons">
                        <img src={IconCenter1} alt='财务管理' className="img-full" />
                    </div>
                    <h6 className="center-nav-title">财务管理</h6>
                </div>
                <div className="center-nav-item fl" onClick={onShare}>
                    <div className="center-nav-icons">
                        <div className="center-nav-statu">
                            <img src={IconHot} alt='hot' />
                        </div>
                        <img src={IconCenter2} alt='我要分享' className="img-full" />
                    </div>
                    <h6 className="center-nav-title">我要分享</h6>
                </div>
                <Link to='/self/next/class' className="center-nav-item fl">
                    <div className="center-nav-icons">
                        <img src={IconCenter3} alt='共同创造' className="img-full" />
                    </div>
                    <h6 className="center-nav-title">共同创造</h6>
                </Link>
                <Link to='/address/list?not_check=1' className="center-nav-item fl">
                    <div className="center-nav-icons">
                        <img src={IconCenter4} alt='收货地址' className="img-full" />
                    </div>
                    <h6 className="center-nav-title">收货地址</h6>
                </Link>
                <Link to='/self/follow' className="center-nav-item fl">
                    <div className="center-nav-icons">
                        <img src={IconCenter5} alt='我的收藏' className="img-full" />
                    </div>
                    <h6 className="center-nav-title">我的收藏</h6>
                </Link>
                <Link to='/qa/index' className="center-nav-item fl">
                    <div className="center-nav-icons">
                        <img src={IconCenter6} alt='帮助中心' className="img-full" />
                    </div>
                    <h6 className="center-nav-title">帮助中心</h6>
                </Link>
            </div>
        </div>

        <Model ref={shareModel}>
            <div className="share-mark" onClick={() => shareModel.current.hide()}>
                <img src={ShareImg} alt='分享给朋友' />
            </div>
        </Model>
    </>)
})

const SelfIndex = () => {

    const [, error] = useSelf()

    return ( <>
        {error && <Empty title={error} />}
        {!error && (<>
            <UHeader />
            <OrderRoot />
            <Console />
        </>)}
    </> );
}
 
export default SelfIndex;