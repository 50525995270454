import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useAxios } from '../../hooks/useAxios'
import Empty from '../../commponents/Empty';

const TreeTow = memo(({list}) => {
    return (<div className='cate-list'>
        {list.map(item => (
            <Link to={'/goods/cate/' + item.id} key={item.id} className="cate-item">
                <img src={item.pic} alt={item.title} className="img-full" />
            </Link>
        ))}
    </div>)
})

const TreeOne = memo(({data}) => {
    return (<>
        <div className="nav-title">
            <strong>{data.title}</strong>
        </div>
        {data.children && <TreeTow list={data.children} />}
    </>)
})

const GoodsCate = () => {
    
    const [cates, error, isPending] = useAxios('goods/cate')

    return ( <article className='win'>
        {isPending && <div className='h6'>正在努力加载...</div>}

        {!isPending && error && <Empty title={error} />}

        {!isPending && !error && cates.map(item => (<TreeOne data={item} key={item.id} />))}
    </article> );
}
 
export default GoodsCate;