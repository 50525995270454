import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import TabBar from "./TabBar";

const Iframe = () => {

    const style = useMemo(() => ({
        height: 'calc(100vh - 1rem)',
        overflowY: 'scroll'
    }), [])

    return ( <>
        <div style={style}>
            <Outlet />
        </div>
        <TabBar />
    </> );
}
 
export default Iframe;