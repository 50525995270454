import { Link } from "react-router-dom";
import Empty from "../../commponents/Empty";
import { useAxios } from "../../hooks/useAxios";
import IconRight from '../../assets/images/icons/icon_boult@2x.png'

const QAList = () => {
    const [result, error, isPending] = useAxios('qa/list')
    return ( <>
        {isPending && <div className="h6">正在加载...</div>}
        
        {error && <article className="win"><Empty title={error} /></article>}

        {result && (<div className="qa-list">
            {result.map(item => (
                <Link to={'/qa/detail/' + item.id} key={item.id} className="qa-item border-bottom">
                    <div className="right-icon fr">
                        <img src={IconRight} alt="查看" />
                    </div>
                    <div className="h5 overhide">{item.title}</div>
                </Link>
            ))}
        </div>)}
    </> );
}
 
export default QAList;