import { forwardRef, useEffect, useState } from "react";

const Model = forwardRef(({children, open}, ref) => {
    const [state, switchState] = useState(open ?? false)
    useEffect(() => {
        ref.current = {
            open: () => switchState(true),
            hide: () => switchState(false)
        }
    }, [ref, switchState])

    return ( <>
       { state && (<div className="popup-view" style={{display: 'block'}}>
            {children}
       </div>) }
    </> );
})
 
export default Model;