import Body from "./commponent/Body";
import Footer from "./commponent/Footer";
import Header from "./commponent/Header";
import { useAxios } from '../../hooks/useAxios'
import Empty from '../../commponents/Empty'
import { Link } from "react-router-dom";
import {  useEffect, useState } from "react";

const ShoppingCart = () => {
    const [result, , isPending, reload] = useAxios('goods/shopping/cart')
    const [goods, setGoods] = useState([])

    useEffect(() => {
        if (result !== null && result.length !== 0) {
            const curGoods = result.map(item => Object.assign({
                checkState: false
            }, item))
            setGoods(curGoods)
        } 
    }, [result])

    return ( <>
        { isPending && <div className="h6">正在努力加载...</div> }
        { goods && goods.length === 0 && (
            <div className="cart-list" style={{paddingTop: '1px'}}>
                <Empty title="暂无商品，快去挑选商品吧">
                    <Link to='/' className="empty-link col_666">去逛逛</Link>
                </Empty>
            </div>
        )}

        { goods && goods.length !== 0 && (<>
            <Header goods={goods} onReload={reload} />
            <article className="single-form">
                <Body goods={goods} onUpdate={setGoods} />
                <Footer goods={goods} onUpdate={setGoods} />
            </article>
        </>) }
    </> );
}
 
export default ShoppingCart;