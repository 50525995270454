import { memo, useCallback, useMemo } from "react";
import { useAxios } from '../../hooks/useAxios'
import Empty from '../../commponents/Empty'
const UHeader = memo(({udata}) => {

    const toDate = useCallback((time) => {
        const date = new Date(time)
        return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('.')
    }, [])

    return (<div className="level-head">
        <div className="user-level clearfix">
            <div className="user-cover fl">
                <img src={udata.cover} alt={udata.name} className="img-full" />
            </div>
            <div className="user-level-data">
                <h2>{udata.name}</h2>
                <p className="col_999">{toDate(udata.craeteTime)} 加入</p>
            </div>
            <div className="user-level-cart">
                <p>我的等级</p>
                <strong>{udata.levelTitle}</strong>
            </div>
        </div>
    </div>)
})

const UProp = memo(({ udata, next }) => {

    const propVal = useMemo(() => {
        const { diff = 0, full = 1 } = next ?? {}
        return ((full - diff) / full * 100).toFixed(2)
    }, [next])

    return (<div className="level-prop border-bottom">
    <h2 className="h2">共同创造中 ｜ 爱心优惠{udata.discount * 10}折</h2>
    <p className="h6">当前距下一级还需消费 ¥{next.diff}</p>
    <div className="level-prop-view">
        <div className="level-name fl">
            {udata.levelTitle}
        </div>
        <div className="level-next fr">{next.title}</div>
        <div className="overhide">
            <div className="level-prop-columnar">
                <i style={{width: propVal + '%'}}></i>
            </div>
        </div>
    </div>
    </div>)
})

const UDes = memo(({ text }) => {
    return (<div className="level-detail">
        <div className="level-title h4">
            创造等级说明
        </div>
        <div className="single-content" dangerouslySetInnerHTML={{__html: text}}></div>
    </div>)
})

const NextClass = () => {
    
    const [result, error, isPending] = useAxios('self/class')
    console.log(result)
    return ( <article className="win">

        {isPending && <div className="h6">正在努力加载...</div>}

        { error && <Empty title={error} /> }

        {result && (<>
            <UHeader udata={result.userData} />
            <UProp udata={result.userData} next={result.next} />
            <UDes text={result.des} />
        </>)}
    </article> );
}
 
export default NextClass;