import { DetailView } from './commponent/DetailView';
import { BottomBar } from './commponent/BottomBar'
import { createContext, useEffect, useState } from 'react';
import { useAxios } from '../../hooks/useAxios';
import { useParams } from 'react-router-dom';
import Empty from '../../commponents/Empty2';

// 提交的表单数据
const GoodsFromData = createContext({})


const GoodsDetail = () => {

    const [formData, upFormData] = useState({})
    const [detailUrl, setDetailUrl] = useState('')
    const [result, error, isPending] = useAxios(detailUrl)

    const { id } = useParams()
    useEffect(() => {
        setDetailUrl('goods/detail/' + id)
    }, [id])

    return ( <>
        {isPending && <div className='h6'>正在加载...</div>}
        {!isPending && error && <Empty title={error} /> }

        {!isPending && result && (<GoodsFromData.Provider value={[formData, upFormData]}>
            <DetailView detail={result} Ctx={GoodsFromData} />
            <BottomBar detail={result} Ctx={GoodsFromData} />
        </GoodsFromData.Provider>)}
    </> );
}
 
export default GoodsDetail;