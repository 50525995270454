import IconMore from '../assets/images/icons/moren.png'
import IconRight from '../assets/images/icons/icon_boult@2x.png'
import { Link } from 'react-router-dom';
import { useAddressID } from '../store/getAddress'
import { useMemo } from 'react';

const AddressItem = ({ data, default_id, checkedState = true }) => {

    const [currrentAddressID, setCurrrentAddressID] = useAddressID()
    
    // 设置选中状态
    const checked = useMemo(() => {
        if (currrentAddressID === null && default_id === data.id) return true
        if (currrentAddressID === data.id) return true
        return false
    }, [currrentAddressID, default_id, data])

    return ( <div className="address-item">
        {checkedState && (<div className="fl">
            <label className="label alone-choice clearfix">
                <div className="fl">
                    <input type="radio" name="address" checked={checked} onChange={() => setCurrrentAddressID(data.id)} />
                    <div className="choice-view2"></div>
                </div>
            </label>
        </div>)}
        
        <div className="address-data overhide">
            <div className="address-region middl-text">
                {default_id === data.id && <img src={IconMore} alt='默认' />}
                <span className="h6">{data.from}</span>
            </div>
            <div className="address-house">
                <Link to={'/address/edit/' + data.id} className="right-icon fr">
                    <img src={IconRight} alt='更多' />
                </Link>
                <strong>{data.detail}</strong>
            </div>
            <div className="h6">
                {data.name} {data.mobile.substring(0, 3) + '****' + data.mobile.substring(7, 11)}
            </div>
        </div>
</div> );
}
 
export default AddressItem;