import { useEffect, useState } from "react";
import Empty from '../../commponents/Empty';
import { useParams } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios";
import GoodsList from "../../commponents/GoodsList";

const GoodsIndex = () => {

    const [goodsUrl, setGoodsUrl] = useState('')
    const [result, error,  isPending] = useAxios(goodsUrl)
    const { cateID } = useParams()

    useEffect(() => {
        setGoodsUrl('goods/cate/' + cateID)
    }, [cateID])

    return ( <article className="win">
        {isPending && <div className='h6'>正在努力加载...</div>}

        {((!isPending && error ) || result?.list?.length === 0) && <Empty title={error ?? '空空如也~'} />}

        {!isPending && !error &&  result?.list?.length !== 0 && <GoodsList goods={result.list} /> }
    </article> );
}
 
export default GoodsIndex;