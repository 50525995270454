import { useParams } from "react-router-dom";
import Empty from "../../commponents/Empty";
import { useAxios } from "../../hooks/useAxios";

const QADetail = () => {

    const { id } = useParams()
    const [ result, error, isPending] = useAxios('qa/detail/' + id)

    return ( <>
        {isPending && <div className="h6">正在加载...</div>}
        {error && <article className="win"><Empty title={error} /></article>}

        {result && <article className="win">
            <div className="single-detail">
                <div className="single-title">
                   {result.title}
                </div>
                <div className="single-content" dangerouslySetInnerHTML={{__html: result.content}}>
                </div>
            </div>
        </article>}
    </> );
}
 
export default QADetail;