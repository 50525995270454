/** 商品列表的搜索历史 */
import { atom, useAtom } from "jotai"
import { useCallback, useEffect } from "react"

// 持久存储到本地
const localKey = 'searchHistory'

// 搜索历史的保存位置
const store = atom(JSON.parse(window.localStorage.getItem(localKey)) ?? [])
export const useSearch = () => {

    const [history, setHistory] = useAtom(store)
    
    /** 新增搜索历史 */
    const pushHistory = useCallback((newItem) => {
        const find = history.find(item => item.text === newItem)
        if (find) return null
        setHistory((orgrin) => {
            orgrin.push({
                text: newItem,
                time: Date.now()
            })
            return orgrin.slice(-10)
        })
    }, [setHistory, history])

    /** 清空搜索历史 */
    const clearHistory = useCallback(() => {
        setHistory([])
    }, [setHistory])

    /** 监听并储存到本地 */
    useEffect(() => {
        window.localStorage.setItem(localKey, JSON.stringify(history))
    }, [history])

    return [history, pushHistory, clearHistory]
}