import { forwardRef, useEffect, useMemo, useState } from "react";
import Model from '../../../commponents/Model';
import IconDel from '../../../assets/images/icons/num-del.png'
import IconAdd from '../../../assets/images/icons/num-add.png'

export const GoodsPopup = forwardRef(({detail, onUpdate, formData, open}, modelRef) => {

    const id = useMemo(() => detail.id, [detail])
    const onClear = () => { modelRef.current.hide() }

    const [number, setNumber] = useState(1)
    const [attr, setAttr] = useState(null)

    useEffect(() => {
        onUpdate && onUpdate({attr,  number, id})
    }, [attr, number, onUpdate, id])

    return <>
        <Model ref={modelRef} open={open}>
            <div className="goods-popup-attr page" onClick={onClear}>
                <div className="goods-popup-bottom" onClick={event => event.stopPropagation()}>
                    <div className="goods-itme clearfix">
                        <div className="goods-item-pic fl">
                            <img src={detail.pic} alt={detail.title} />
                        </div>
                        <div className="goods-item-data">
                            <div className="goods-item-title h5"></div>
                            <div className="goods-item-val middl-text">
                                <b className="col_b01f29 shopping-val">
                                    <span>¥</span>{formData?.attr?.price ?? detail.price}
                                </b>
                            </div>
                            <div className="goods-item-txt col_999">库存：{detail.store}件</div>
                        </div>
                    </div>
                    <h6 className="h6">规格</h6>
                    <div className="goods-choice clearfix">
                        {detail.attr.map((item, index) => (<label key={index} className='label choice-label1 fl'>
                            <input type="radio" name="label" title={item.title} onChange={() => setAttr(item)} />
                            <div className="choice-view substr"> {item.title} </div>
                        </label>))}
                    </div>
                    <div className="goods-pay-num clearfix">
                        <div className="fl">
                            <h6 className="h6">数量</h6>
                        </div>
                        <div className="fr goods-num-view middl-text">
                            <div className="goods-num-edit">
                                <img src={IconDel} alt="数量减" onClick={() => setNumber(cur => Math.max(1, cur - 1))}  />
                            </div>
                            <div className="goods-num-input">
                                <input type={'number'} value={number} onChange={(event) => setNumber(parseInt(event.target.value))}/>
                            </div>
                            <div className="goods-num-edit">
                                <img src={IconAdd} alt="数量加" onClick={() => setNumber(cur => cur + 1)} />
                            </div>
                        </div>
                    </div>
                    <div className="goods-popup-btn">
                        <button type="submit" className="btn-1em bg_d6b575">确定</button>
                    </div>
                </div>
            </div>

        </Model>
    </>
})