import Icon from '../../../assets/images/icons/icon_more@2x.png'
import { memo } from "react";

export const GoodsAttr = memo(({detail, formData, onShowPopup}) => {

    return (<>
        <div className="goods-attr">
            <div className="line-view border-bottom">
                <div className="fr more-icon" onClick={onShowPopup} >
                    <img src={Icon} alt="选择规格" />
                </div>
                <div className="line-title fl">
                    <strong>规格</strong>
                </div>
                <div className="line-val">
                    {formData?.attr?.title ?? '请选择'}
                </div>
            </div>
            <div className="line-view">
                <div className="line-title fl">
                    <strong>运费</strong>
                </div>
                <div className="line-val">
                    {detail.freight || '免运费'}
                </div>
            </div>
        </div>
    </>)
})