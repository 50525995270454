import IconAddress from '../../assets/images/slices/icon_address@2x.png'
import IconWait from '../../assets/images/icons/wait.png'
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Empty from "../../commponents/Empty";
import { useAxios } from "../../hooks/useAxios";
import PrintSimple from './commponents/PrintSimple';
import { useMessage } from '../../hooks/useMessage';

const DetailView = memo(({data}) => {

    // 设置订单状态
    const stateData = useMemo(() => {
        const state = {
            title: '已付款',
            icon: IconWait
        }
        if (data.state === 1 && data.logistic) {
            state.title = '已发货'
        }
        if (data.state === 2) {
            state.title = '已完成'
        }
        return state
    }, [data])

    // 手机号
    const mobile = useMemo(() => {
        const address = data.address ?? {}
        const { mobile  = '' } = address
        return mobile.substring(0, 3) + '****' + mobile.substring(7, 11)
    }, [data])

    // 商品总价
    const val = useMemo(() => {
        let val = 0
        data?.goods?.forEach(element => {
            val += element.number * element.price 
        })
        return val.toFixed(2)
    }, [data])

    // 2022/10/25 14:45:00
    const codeDate = useCallback((time) => {
        const date = new Date(time)
        return [
            [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('/'),
            [date.getHours(), date.getMinutes(), date.getSeconds()].join(':')
        ].join(' ')
    }, [])


    return (<div className="order-detail">
        <div className="middl-text order-status">
			<img src={stateData.icon} alt='订单状态' />
			<strong>{stateData.title}</strong>
		</div>
		<div className="simple-address">
			<div className="middl-text simple-addname">
				<img src={IconAddress} alt='地址' />
				<strong className="h5">{data?.address?.name} {mobile}</strong>
			</div>
			<div className="simple-addtarget col_999">
				地址：{data.address.from} {data.address.detail} 
			</div>
		</div>
        <div className="order-item">
			<div className="single-form-item">
                {data.goods.map((good, index) => (<div key={good.id + index} className="goods-itme clearfix">
					<div className="goods-item-pic fl">
						<img src={good.pic} alt={good.title} />
					</div>
					<div className="goods-item-data">
						<div className="clearfix h5">
							<span className="fr col_999"> x{good.number}</span>
							<div className="substr">
                                {good.title}
							</div>
						</div>
						<div className="goods-item-txt ">
							<span className="fr">等级价 ¥{good.price}</span>
							<span className="col_999">{good.attr}</span>
						</div>
					</div>
				</div>))}
            </div>
        </div>

        <div className="order-item">
            <PrintSimple val={val} />
		</div>

        {/* <div className="order-item">
			<div className="order-lines">
				<div className="clearfix">
					<div className="fl">快递公司：</div>
					<div className="overhide">申通快递</div>
				</div>
				<div className="clearfix">
					<div className="fl">快递单号：</div>
					<div className="overhide">22558488222455</div>
				</div>
			</div>
		</div> */}

        <div className="order-item">
			<div className="order-lines">
				<div className="clearfix">
					<div className="fl">订单编号：</div>
					<div className="overhide">{data.order_no}</div>
				</div>
				<div className="clearfix">
					<div className="fl">下单时间：</div>
					<div className="overhide">{codeDate(data.create_time)}</div>
				</div>
				<div className="clearfix">
					<div className="fl">支付方式：</div>
					<div className="overhide">微信支付</div>
				</div>
				<div className="clearfix">
					<div className="fl">支付时间：</div>
					<div className="overhide">{codeDate(data.pay_time)}</div>
				</div>
                { data.end_time && (<div className="clearfix">
					<div className="fl">完成时间：</div>
					<div className="overhide">{codeDate(data.end_time)}</div>
				</div>) }
			</div>
		</div>
    </div>)
})

const ButtonBtn = memo(({ data, onOrderEnd }) => {

    const [message] = useMessage()
    const [postCoveUrl, setPostCove] = useState('')
    const option = useMemo(() => {
        return { method: 'post' }
    }, [])
    const [result, error ] = useAxios(postCoveUrl, option)
    useEffect(() => {
        if (error) message({title: error})
        if (result) {
            message({title: result.msg})
            onOrderEnd && onOrderEnd()
        }
    }, [result, error, message, onOrderEnd])
    
    return (<>
        <div className="footer-view">
		    <div className="bottom-position page order-bottom-btns">
                <span className='col_d6b575' onClick={() => setPostCove('order/end/' + data.order_no)}>确认收货</span>
            </div>
        </div>
    </>)
})

const OrderDetail = () => {

    const { order_no } = useParams()
    const [detailUrl, setDetailUrl] = useState('order/detail/' + order_no)
    const [result, error, isPending] = useAxios(detailUrl)

    return ( <>
        {isPending && <div className="h6">正在努力加载...</div>}
        {error && <article className="win"> <Empty title={error} /> </article>}
        { result && (<>
            <DetailView data={result} />
            { result.state === 1 && <ButtonBtn data={result} onOrderEnd={ () => setDetailUrl(url => url + '?_=' + Date.now()) } /> }
        </>) }
    </> );
}
 
export default OrderDetail;