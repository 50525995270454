import { forwardRef, useCallback, useEffect, useState } from 'react';
import './city.css'
import citys from './city.json'
import PickerCol from './CityCol';

const filterItem = item => {
    const { name, code } = item
    return { name, code } 
}

const PickerCity = forwardRef(({ title, onIndexChange, onValueChange }, ref) => {

    const [state, setState] = useState(false)
    const [indexs, setIndexs] = useState([])
    const [list, setList] = useState([])
    
    // 对外暴漏的方法
    useEffect(() => {
        if (ref !== undefined) {
            ref.current = {
                open: () => setState(true),
                close: () => setState(false)
            }
        }
    }, [ref])

    // 未选中数据初始化
    useEffect(() => {
        if (list.length === 0) {
            // 默认选中第一项
            const startList = []
            let cur = citys
            while (cur && cur.length) {
                const parent = startList[startList.length - 1]?.list ?? []
                startList.push({
                    key: parent[0]?.code ?? 0,
                    list: cur.map(filterItem)
                })
                cur = cur[0].children
            }
            setList(startList)
            setIndexs(startList.map(() => 0))
        }
    }, [list])

    // 当列表改变时
    const onListChange = useCallback((changeIndex, itemIndex) => {
        const sliceIndexs = indexs.slice(0, itemIndex + 1)
        sliceIndexs[itemIndex] = changeIndex
        
        let cur = citys
        const newList = []
        while (cur && cur.length) {
            // 给新出现的子项设置默认值
            const curIndex = newList.length
            const item = list[curIndex]
            if (sliceIndexs[curIndex] !== undefined) {
                newList.push(item)
                cur = cur[sliceIndexs[curIndex]]?.children
            } else {
                const key = newList[curIndex - 1].list[sliceIndexs[curIndex - 1] ?? 0]?.code 
                newList.push({ key, list: cur })
                cur = cur[0]?.children
                sliceIndexs.push(0)
            }
        }
        setIndexs(sliceIndexs)
        setList(newList)
        onIndexChange && onIndexChange(sliceIndexs)


        // 打包值
        const values = newList.map((item, index) => {
            return item.list[sliceIndexs[index]].name
        })
        onValueChange && onValueChange(values.join(' '))
    }, [indexs, list, onIndexChange, onValueChange])

    return ( state && (<aside className='picker'>
        <article className='picker-view'>
            <section className='header'>
                <span className='close' onClick={() => setState(false)}>取消</span>
                {title && <div className='title substr'>{title}</div>}
                <strong className='confirm' onClick={() => {
                    setState(false)
                    onIndexChange && onIndexChange(indexs)

                    // 打包值
                    const values = list.map((item, index) => {
                        return item.list[indexs[index]].name
                    })
                    onValueChange && onValueChange(values.join(' '))
                }}>确认</strong>
            </section>
            <section className='body'>
                { list.map((item, itemIndex) => <PickerCol key={item.key} list={item.list} onIndexChange={changeIndex => onListChange(changeIndex, itemIndex)} />) }
            </section>
        </article>
    </aside>));
})
 
export default PickerCity;