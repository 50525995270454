import IconLogo from '../../../assets/images/icons/leaf1.png'
import IconLeft from '../../../assets/images/icons/icon_boult2@2x.png'
import { memo, useCallback, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useConfirm, useMessage } from '../../../hooks/useMessage'
import { useAxios } from '../../../hooks/useAxios';

const Header = memo(({ goods, onReload }) => {

    console.log(goods)
    const [message] = useMessage()
    const [confirm] = useConfirm()

    const [deleteUrl, setDeleteUrl] = useState('')
    const [option, setOption] = useState({method: 'POST'})
    const [result, error] = useAxios(deleteUrl, option)

    // 发起删除
    const onRemove = useCallback(() => {
        const removeList = goods.filter(item => item.checkState)
        if (removeList.length === 0) { return message({title: '请先选择商品'}) }
        confirm({
            content: `已选中(${removeList.length})件商品, 是否将选中商品从购物车中移除`,
            success() {
                const data = {
                    goods: removeList.map(item => ({
                        id: item.id,
                        attr: item.attr.title
                    }))
                }
                
                setDeleteUrl('goods/shopping/cart/del')
                setOption(option => ({...option, data}))
            }
        })
    }, [confirm, message, goods])

    // 监听请求返回
    useEffect(() => {
        if (error) { message({title: error}) }
        if (result) {
            message({title: result.msg})
            onReload && onReload()
        }
    }, [result, error, message, onReload])

    return (
        <div className="nav-head">
            <div className="cart-head page">
                <Link to='/' className="fl middl-text cart-shop">
                    <img src={IconLogo} alt="本源甘露" />
                    <span>本源甘露</span>
                    <img src={IconLeft}  alt='' />
                </Link>
                <span className="fr h4" onClick={onRemove}>
                    删除
                </span>
            </div>
        </div>
    )
})

export default Header