import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import IconWxPay from '../../assets/images/icons/wechat-pay.png'
import AddressDefault from "../../commponents/AddressDefault";
import { useAxios } from "../../hooks/useAxios";
import Empty from '../../commponents/Empty2'
import { useMessage } from '../../hooks/useMessage'
import PrintSimple from "./commponents/PrintSimple";

const PayType = memo(() => {
    return (<div className="single-form-item">
    <div className="line-view clearfix">
        <div className="line-title h5 fl">
            支付方式
        </div>
        <div className="fr">
            <div className="wechat-pay middl-text">
                <img src={IconWxPay} alt='微信' />
                <span className="h5">微信支付</span>
            </div>
        </div>
    </div>
</div>)
})

const GoodsList = memo(({goods}) => {
    return (<div className="single-form-item">
    {goods.map((good,index) => (<Link to={'/goods/detail/' + good.id } key={index} className="goods-itme clearfix">
        <div className="goods-item-pic fl">
            <img src={good.pic} alt={good.title} />
        </div>
        <div className="goods-item-data">
            <div className="clearfix h5">
                <span className="fr col_999"> x{good.number}</span>
                <div className="substr">
                    {good.title}
                </div>
            </div>
            <div className="goods-item-txt col_999">{good.attr}</div>
            <div className="goods-item-val h6">
                <div className="col_b01f29 h6">
                    等级价 ¥168.6
                </div>
            </div>
        </div>
    </Link>))}
</div>)
})


const PayButton = memo(({ value, order_no, address_id }) => {

    const [option, setOption] = useState({ method: 'post' })
    const [postUrl, setPostUrl] = useState('')
    const [result, error, isPending] = useAxios(postUrl, option)
    
    // 请求返回结果
    const [message] = useMessage()
    const onSumbit = useCallback(() => {
        if (isPending && postUrl) {
            message({title: '正在努力发送中....'})
        } else if (address_id === null) {
            message({title: '请先选择地址~'})
        } else {
            setOption(option => Object.assign({}, option, { data: {address_id} }))
            setPostUrl('order/over/' + order_no)
        }
    }, [order_no, address_id, message, isPending, postUrl])


    const navTo = useNavigate()
    useEffect(() => {
        if (isPending === false) {
            if (error) message({title: error})
            if (result) message({title: result.msg})

            navTo('/order/result/' + order_no + '/' + (error ? '0' : '1'))
        }
    }, [error, result, message, order_no, navTo, isPending])

    return (<div className="form-bottom">
        <div className="form-bottom-view page">
            <div className="order-bottom">
                <div className="fr" onClick={onSumbit}>
                    <button type="submit" className="btn-1em bg_d6b575 form-bottom-btn">确定</button>
                </div>
                <div className="overhide">
                    <div className="col_b01f29 goods-val">
                        <strong>
                            <span>¥</span>{value}
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    </div>)
})

const OrderSubmit = () => {

    const {order_no} = useParams()
    const [orderDeatilUrl, setOrderDetailUrl] = useState('')
    const [order, error ] = useAxios(orderDeatilUrl)
    const [address_id, setAddressId] = useState(null)
    const val = useMemo(() => {
        let val = 0
        order?.goods?.forEach(element => {
            val += element.number * element.price 
        })
       
        return val.toFixed(2)
    }, [order])
   
    useEffect(() => {
        if (order_no) setOrderDetailUrl('order/simple/' + order_no)
    }, [order_no])

    return ( <div className="single-form">
        
        {error && <Empty title={error} />}

        {order && (<>
        
            <AddressDefault onAddressIDChange={setAddressId} />

            <PayType />

            <GoodsList goods={order.goods} />

            <PrintSimple goods={order?.goods ?? []} val={val} />

            <PayButton value={val} order_no={order_no} address_id={address_id} />
        </>)}
    </div> );
}
 
export default OrderSubmit;