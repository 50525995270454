import { memo } from "react";
import IconTitleLeft from '../../../assets/images/icons/title-left.png'
import IconTitleRight from '../../../assets/images/icons/title-right.png'

export const GoodsContentTitle = memo(() => {
    return (
        <div className="goods-detail-title middl-text">
			<img src={IconTitleLeft} alt="" />
			<span className="h6">详情</span>
			<img src={IconTitleRight} alt="" />
		</div>
    )
})