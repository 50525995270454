import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AddressItem from '../../commponents/AddressItem';
import Empty from '../../commponents/Empty'
import { useAxios } from '../../hooks/useAxios';

const AddressList = () => {

    const local = useLocation()
    const checkState = useMemo(() => {
        return /not_check/.test(local.search ?? '') === false
    }, [local])
    console.log(checkState)
    const [result, error, isPending] = useAxios('address/list')
 
    return ( <>
        <div className="address-list">
            {isPending && <div className='h6'>正在获取地址</div>}

            { error && <Empty title={error} /> }

            { result && result?.list?.map(item => ( <AddressItem checkedState={checkState} key={item.id} data={item} default_id={result.default} /> )) }
            
        </div>

        <div className="form-bottom">
		<div className="form-bottom-view page">
            <Link to="/address/push" className='btn-1em bg_d6b575 form-bottom-btn address-push-btn'>+ 新增地址</Link>
		</div>
	</div>	
    </> );
}
 
export default AddressList;