import { memo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
// 商品广告
export const GoodsSwiper = memo(({ads}) => {
	
	const [current, setCurrent] = useState(1)

    return ( <div className="goods-swiper">
		<Swiper
			autoplay={{
				delay: 5000,
				disableOnInteraction: false,
			}}
			onSlideChange={(swiper) => { setCurrent(swiper.activeIndex + 1) }}
			modules={[Autoplay]}
      	>
			{ads.map((img, index) => (<SwiperSlide key={index}>
				<div className="goods-ad-link">
					<img src={img} alt="" className="img-full"/>
				</div>
			</SwiperSlide>))}
	  	</Swiper>
		<div className="goods-swiper-num">
			<span>{current}</span>/<span>{ads.length}</span>
		</div>
    </div>)
})
