import { memo, useCallback, useEffect, useMemo, useState, useTransition } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Empty from "../../commponents/Empty2";
import { useAxios } from "../../hooks/useAxios";
import { useMessage } from "../../hooks/useMessage";

const ListHeader = memo(({state, onSwitch}) => {

    const bars = useMemo(() => {
        return [
            {state: '-1', title: '全部'},
            {state: '0', title: '待付款'},
            {state: '1', title: '待确认'},
            {state: '2', title: '已完成'},
        ]
    }, [])

    return (<div className="tab-head flex-line container">
        {bars.map(item => (<div key={item.state} onClick={() => onSwitch && onSwitch(item.state)} className={'tab-nav h5 ' + (item.state === state ? 'on' : '') }>
            {item.title}
        </div>))}
    </div>)
})

const OrderItem = memo(({order}) => {
    const stateText = useMemo(() => ['代付款', '待确认', '已完成'], [])
    const counter = useMemo(() => {
        let number = 0
        let price = 0
        order.goods.forEach(good => {
            number += good.number
            price += good.price * good.number
        })
        price = price.toFixed(2)
        return { price, number }
    }, [order])

    const navTo = useNavigate()
    const onJump = useCallback((order_no, state) => {
        if (state === 0) navTo('/order/submit/' + order_no)
        if (state === 1 || state === 2) navTo('/order/detail/' + order_no)
    }, [navTo])

    return (<div  className="order-item">
        <div className="order-num">
            订单编号:{order.order_no}
            <span className="fr col_d6b575">{stateText[order.state]}</span>
        </div>
        <div className="single-form-item">
            {order.goods.map((good, index) => (<div key={good.id + index} className="goods-itme clearfix" onClick={() => onJump(order.order_no, order.state)}>
                <div className="goods-item-pic fl">
                    <img src={good.pic} alt={good.title} />
                </div>
                <div className="goods-item-data">
                    <div className="clearfix h5">
                        <span className="fr col_999"> x{good.number}</span>
                        <div className="substr">
                            {good.title}
                        </div>
                    </div>
                    <div className="goods-item-txt ">
                        <span className="fr mini-title">等级价 ¥{good.price}</span>
                        <span className="col_999">{good.attr}</span>
                    </div>
                </div>
            </div>))}
        </div>
        <div className="order-count h6">
            共{counter.number}件商品 合计：¥<span className="h3">{counter.price}</span>
        </div>
    </div>)
})

const ListBody = memo(({list}) => {

    return (<div className="order-list bg_f7f7f7">
        {list.map((item, index) => (<OrderItem key={index + item.order_no} order={item} />))}      
    </div>)
})

const OrderList = () => {

    const { state } = useParams()
    const [curState, setState] = useState(state)
    const onSwitch = useCallback((state) => {
        if (state !== curState) {
            let postState = undefined
            if (state !== '-1') {
                postState = parseInt( state )  
            }
            setOption(option => {
                option.params.state = postState
                option.params.start = 0
                return {...option}
            })
            setList([])
            setState(state)
        }
    }, [curState])

    const [url] = useState('order/list')
    const [option, setOption] = useState({ params: {
        start: 0,
        end: 5,
        state: state === '-1' ? undefined : state
    } })
    const [done, setDone] = useState(true)
    const [list, setList] = useState([])
    // 异步列表
    const [isPendingList, startTransition ] = useTransition()
    const [result, error, isPending] = useAxios(url, option)

    // 处理返回到数据
    useEffect(() => {
        if (result) {
            const { done, list: newList } = result
            setDone(done) // 确认是否可以加载下一页
            startTransition(() => { // 异步渲染
                setList(list => {
                    const pushList = newList.filter(item => list.findIndex(curItem => curItem.order_no === item.order_no) === -1)
                    list.push(...pushList)
                    return [...list]
                })
            })
        }
    }, [result])

    // 滚动加载更多
    const [message] = useMessage()
    const onScroll = useCallback((element, done) => {
        const { scrollTop, scrollHeight, offsetHeight } = element
        if (scrollTop === scrollHeight - offsetHeight ) {
            clearTimeout(element.nextPageTimer)
            element.nextPageTimer = setTimeout(() => {
                if (done === true) return message({title: '已加载全部...'})
                setOption(option => {
                    const { params } = option
                    console.log(params.end, params.start)
                    Object.assign(params, { start: params.start + params.end })
                    return Object.assign({}, option, { params })
                })
            }, 150)
        }
    }, [message])

    return ( <article className="win">
        <ListHeader state={curState} onSwitch={onSwitch} />

        {isPending && <div className="h6">正在努力加载...</div>}

        {isPendingList && <div className="h6">正在处理...</div>}

        {list.length === 0 && error && <Empty title={error} />}
        { !isPending && !isPendingList && <div className="scroll-view" onScroll={event => onScroll(event.target, done)}><ListBody list={list} /></div> }
    </article> );
}
 
export default OrderList;