import {
    createBrowserRouter as createRouter,
    Route, createRoutesFromElements, redirect
} from "react-router-dom"
import HomeIndex from "../views/home/Index"
import SearchIndex from "../views/search/Index"
import GoodsDetail from "../views/goods/Detail"
import NewsIndex from "../views/news/Index"
import NewsDetail from "../views/news/Detail"
import Iframe from "../commponents/Iframe"
import GoodsCate from "../views/goods/Cate"
import GoodsIndex from "../views/goods/Index"
import ShoppingCart from "../views/shopping/Cart"
import OrderSubmit from "../views/order/Submit"
import AddressList from "../views/address/List"
import AddessPush from "../views/address/Push"
import AddessEdit from "../views/address/Edit"
import OrderResult from "../views/order/Result"
import OrderDetail from "../views/order/Detail"
import SelfIndex from "../views/Self/Index"
import NextClass from "../views/Self/NextClass"
import OrderList from "../views/order/List"
import QAList from "../views/qa/Index"
import QADetail from "../views/qa/Detail"
import SelfFollow from "../views/Self/Follow"

export const router = createRouter(createRoutesFromElements((
    <>
        {/* 带底部切换栏的页面 */}
        <Route path="/iframe" element={<Iframe />}>

            {/* 首页 */}
            <Route path="home" element={<HomeIndex />} />

            {/* 商品相关 */}
            <Route path="goods/cate" element={<GoodsCate />} />

            {/* 购物车 */}
            <Route path="shopping/card" element={<ShoppingCart />} />

            {/* 个人中心 */}
            <Route path="self/index" element={<SelfIndex />} />
        </Route>

        {/* 搜索页 */}
        <Route path="/search" element={<SearchIndex />}></Route>

        {/* 商品相关 */}
        <Route path="/goods/cate/:cateID" element={<GoodsIndex />} />
        <Route path="/goods/detail/:id" element={<GoodsDetail />} />

        {/* 订单 */}
        <Route path="/order/list/:state" element={<OrderList />} />
        <Route path="/order/submit/:order_no" element={<OrderSubmit />} />
        <Route path="/order/detail/:order_no" element={<OrderDetail />} />
        <Route path="/order/result/:order_no/:state" element={<OrderResult />} />

        {/* 地址管理 */}
        <Route path='/address/list' element={<AddressList />}  />
        <Route path="/address/push" element={<AddessPush />} />
        <Route path="/address/edit/:address_id" element={<AddessEdit />} />

        {/* 新闻详情相关 */}
        <Route path="/news" element={<NewsIndex />} />
        <Route path="/news/detail/:id" element={<NewsDetail />} />

        {/* 个人中心相关 */}
        <Route path="/self/next/class" element={<NextClass />} />
        <Route path="/self/follow" element={<SelfFollow />} />

        {/* 帮助中心 */}
        <Route path="/qa/index" element={<QAList />} />
        <Route path="/qa/detail/:id" element={<QADetail />} />

        {/* 路由重定向, / =>  /iframe/home */}
        <Route path="/" loader={() => {
            return redirect('/iframe/home')
        }} element={<div className="h5">正在跳转...</div>} />
    </>
)))