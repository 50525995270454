/** 文章为空 */
import ImageEmpty from '../assets/images/slices/empty2.png'

const Empty = ({ title = '没有找到相关的内容' }) => {
    return ( <div className="empty-view">
        <div className="empty-view-pic">
            <img src={ImageEmpty} alt={title} />
        </div>
        <div className="empty-view-font">
            {title}
        </div>
    </div> );
}
 
export default Empty;