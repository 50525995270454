import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import {useAxios} from '../hooks/useAxios'

// 如果为null, 找默认地址, 如果不为null
const currrentAddressID = atom(null)

export const useAddressID = () => {
    const [id, setAddressID] = useAtom(currrentAddressID)
    return [id, setAddressID]
}


export const useAddress = () => {
    const [currrentID ] = useAtom(currrentAddressID)
    const [getAddressUrl, setGetAddressUrl] = useState('')
    const [address, error, isPending] = useAxios(getAddressUrl)

    useEffect(() => {
        if (currrentID === null) setGetAddressUrl('address/default')
        else setGetAddressUrl('address/detail/' + currrentID)
    }, [currrentID])

    return [address, error, isPending]
}