import { memo } from "react";
// 商品简要
export const GoodsData = memo(({detail, formData}) => {
    return (<div className="goods-data">
    <div className="clearfix">
        <div className="fl col_b01f29 goods-val">
            <strong>
                <span>¥</span>{formData?.attr?.price ?? detail.price}
            </strong>
        </div>
        <div className="fl col_999 goods-deline">原价 {((formData?.attr?.price ?? detail.price) * 1.2).toFixed(2)}</div>
    </div>
    <div className="goods-title">
        <h2 className="h2">{detail.title}</h2>
    </div>
    <div className="goods-tips col_999 clearfix">
        <span className="fl">货源地：{detail.place}</span>
        <span className="fr">库存：{detail.store}</span>
    </div>
</div>)
})