import IconDel from '../../../assets/images/icons/num-del.png'
import IconAdd from '../../../assets/images/icons/num-add.png'
import { useEffect, useState } from 'react';
const GooodsNumber = ({good, onUpdate}) => {

    const [number, setNumber] = useState(good.number)

    useEffect(() => {
        if (good.number !== number) {
            good.number = number
            onUpdate(goods => [...goods])
        }
    }, [number, onUpdate, good])

    return ( <>
        <div className="fr goods-num-view middl-text">
            <div className="goods-num-edit" onClick={() => setNumber(cur => Math.max(1, cur - 1))}>
                <img src={IconDel} alt='' />
            </div>
            <div className="goods-num-input">
                <input value={number} type="number" onChange={event => setNumber(parseInt(event.target.value))} data-max="3000" data-val="168.6" data-id="123"/>
            </div>
            <div className="goods-num-edit" onClick={() => setNumber(cur => cur + 1)}>
                <img src={IconAdd} alt='' />
            </div>
        </div>
    </> );
}
 
export default GooodsNumber;