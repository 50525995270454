import GoodsList from "./GoodsList";

const GoodsListTitle = ({title, goods}) => {
    return ( <>
        <div className="good-way">
            <div className="good-way-title">
                <h3>{title}</h3>
            </div>
            <GoodsList goods={goods} />
        </div>
    </> );
}
 
export default GoodsListTitle;