import { memo } from "react";
import { Link } from "react-router-dom"

export const GoodsPromote = memo(({goods}) => {
    return (<div className="goods-promote">
    <h5 className="h6">为你推荐</h5>
    <div className="promote-list clearfix">
        {goods.map(item => (<Link className="fl goods-simple" to={'./../' + item.id} key={item.id}>
            <div className="goods-simple-pic">
                <img src={item.pic} alt={item.title} />
            </div>
            <div className="goods-simple-title">
               {item.title}
            </div>
            <div className="goods-simple-val col_b01f29">
                <strong>¥ {item.price}</strong>
            </div>
        </Link>))}
      
    </div>
</div>)
})
